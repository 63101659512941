import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
            background: '#fcfcfc',
            primary: '#2a5d77',
            secondary: '#153e52',
            accent: '#113448',
            error: '#14BF98',
            info: '#2F90B0',
            success: '#14BF98',
            warning: '#f98e35',
            red:'#ba0000'
        },
        dark: {
            background: '#2a5d77',
            primary: '#2a5d77',
            secondary: '#153e52',
            accent: '#113448',
            error: '#14BF98',
            info: '#2F90B0',
            success: '#14BF98',
            warning: '#f98e35',
            red:'#ba0000'
        },
      },
    },
  });
