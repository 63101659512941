let french = {
  useq: 'UTILISER QUANTEEC',
  getq: 'OBTENIR QUANTEEC',
  signin: 'CONNEXION',
  pleaseent: 'Merci d\'entrer votre Client ID pour être redirigé vers votre espace en ligne.',
  create: 'CRÉER VOTRE COMPTE',
  textcreate: 'QUANTEEC représente la solution idéale pour adresser les contraintes actuelles de volume, qualité et ressources, présentes exponentiellement dans le domaine de la diffusion vidéo sur Internet.',
  textcreate2: 'Enregistrez-vous ci-dessous et compléter le formulaire pour avoir un accès immédiat à QUANTEEC.',
  signup: 'CRÉER COMPTE',
  signuptxt: 'Merci de remplir les éléments ci-dessous pour avoir immédiatement accès à <span style="color:white"> QUANTEEC</span>. Pour toute question, envoyez-nous un message à <a href="mailto:contact@quanteec.com" style="color:white">contact@quanteec.com</a>.',
  firstn: 'Prénom*',
  lastn: 'Nom*',
  company: 'Nom de l\'entreprise',
  phone: 'Téléphone',
  platformcheck: 'Besoin de Quanteec Platform (streaming + ingestion + encoding + storage)',
  product: 'Produit',
  required: '*requis',
  validate: 'VALIDER',
  reset: 'EFFACER',
  okaccount: 'COMPTE CRÉÉ',
  emailacc: 'Merci pour votre votre intérêt pour Quanteec. Nous reviendrons rapidement vers vous pour créer votre compte.',//'Votre compte a été créé. Les informations de connexion ont été envoyées à l\'adresse email:',
  verifyspam: '. Vérifiez votre dossier SPAM si vous n\'avez rien reçu.',
  url: 'URL d\'accès direct à votre espace en ligne',
  username: 'Nom d\'utilisateur:',
  passwd: 'Mot de passe:',
  erroracc: 'Erreur lors de la création du compte',
  close: 'Fermer',
  error: 'Erreur',
  errortype: 'Ce client n\'existe pas. Merci de vous enregistrer ou de contacter QUANTEEC à: <a href="mailto:support@quanteec.com" style="color:white">support@quanteec.com</a>'

}

  export {french}