import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import {french} from './lang/french'
import {english} from './lang/english'

Vue.use(MLInstaller)

export default new MLCreate({
  initial: 'EN',
  save: process.env.NODE_ENV === 'production',
  languages: [
    new MLanguage('EN').create(english),
    new MLanguage('FR').create(french)
  ]
})