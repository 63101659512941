let english = {
    useq: 'USE QUANTEEC',
    signin: 'SIGN IN',
    pleaseent: 'Please enter your Client ID to redirect you to your account.',
    getq: 'GET QUANTEEC',
    create: 'CREATE YOUR ACCOUNT',
    textcreate: 'QUANTEEC represents the ultimate solution to address today’s volume, quality and resource constraints, increasingly present in the field of video streaming on the Internet.',
    textcreate2: 'Click the below button and fill the forms to try QUANTEEC immediately.',
    signup: 'SIGN UP',
    signuptxt: 'Just fill the forms below and immediately get your hands on <span style="color:white"> QUANTEEC</span>. If you have any questions or need more info, send us a message at <a href="mailto:contact@quanteec.com" style="color:white">contact@quanteec.com</a> and we will get back to you right away.',
    firstn: 'First name*',
    lastn: 'Last name*',
    company: 'Company name',
    phone: 'Phone',
    platformcheck: 'I want Quanteec Platform (streaming + ingestion + encoding + storage)',
    product: 'Product',
    required: '*required',
    validate: 'VALIDATE',
    reset: 'RESET FORM',
    okaccount: 'ACCOUNT CREATED',
    emailacc: 'Thank you for your interest in Quanteec. We will come back shortly to create your account.',//'Your account has been created. Your connection details have been sent by email to the following address:',
    verifyspam: '. Verify your SPAM folder if you don\'t find it.',
    url: 'URL of direct access to your account',
    username: 'Username:',
    passwd: 'Password:',
    erroracc: 'Error creating the account',
    close: 'Close',
    error: 'Error',
    errortype: 'The client does not exist. Please sign up or contact Quanteec at: <a href="mailto:support@quanteec.com" style="color:white">support@quanteec.com</a>'
  }

  export {english}