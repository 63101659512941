<template>
<v-theme-provider dark>
  <v-app-bar
      app
      dark
      color="accent"  
    >
      <div class="d-flex align-center" style="margin-right:1rem">
        <a href="https://quanteec.com"><v-img
          alt="Quanteec"
          contain
          :src="require('@/assets/logo_Q_W.svg')"
          width="115px"
        /></a>
      </div>

      <v-btn
        size="sm"
        :class="($ml.current === lang) ? 'btn-greenw':'btn-trans'"
        v-for="lang in $ml.list"
        :key="lang"
        @click="$ml.change(lang)"
        v-text="lang"
      ></v-btn>

      <v-spacer></v-spacer>


      <!--<v-switch
        v-model="$vuetify.theme.dark"
        :label="`Dark Theme`"
      ></v-switch>

      <v-btn
        href="https://quanteec.com"
        target="_blank"
        text
      >
        <span class="mr-2">Learn more</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      -->
    </v-app-bar>
</v-theme-provider>
</template>

<script>
export default {
  name: 'Navbar',
  data: () => ({
    
  }),
};
</script>

<style scoped lang="scss">

.btn-greenw {
  color: #14BF98  !important;
  background-color: #fff  !important;
  border: 0.1rem solid #2a5d77 !important;
  border-radius: 0.25rem !important;
  transition: .15s all ease-in-out;
  min-width: 0px !important;
  &:hover {
    color: #048F94 !important;
    border: 0.1rem solid #048F94 !important;
    background-color: #FBFCFC !important;
    text-decoration: none;
  }
}
.btn-trans {
  color: #FBFCFC !important;
  background-color: transparent  !important;
  border: 0.1rem solid transparent !important;
  border-radius: 0.25rem !important;
  transition: .15s all ease-in-out;
  min-width: 0px !important;
  &:hover {
    color: #048F94 !important;
    border: 0.1rem solid #048F94 !important;
    background-color: #FBFCFC !important;
    text-decoration: none;
  }
}


</style>
